































import StatisticCard from '@/modules/dashboard/ui/StatisticCard.vue';
import {
  computed,
  defineComponent,
  PropType
} from '@vue/composition-api';
import {
  IndustryStatistic,
  useGenerateStatistics
} from '../helpers/generate-statistics';

export default defineComponent({
  components: { StatisticCard },
  name: 'StatisticList',
  props: {
    statisticData: {
      type: Object as PropType<IndustryStatistic>,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  setup(props) {
    const generateStatistics = () => {
      const {
        total,
        verified,
        unverified,
        // micro,
        small,
        medium,
        products
      } = useGenerateStatistics(props.statisticData, {
        forLanding: true
      });

      const statistics = [
        total,
        verified,
        // micro,
        small,
        medium
      ];

      return statistics;
    };

    const statistics = computed(() => generateStatistics());

    return {
      statistics
    };
  }
});
